//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import likable from '~/plugins/mixins/likable';
import productSizes from '~/plugins/mixins/productSizes';
import SaveFavorite from '~/components/general/SaveFavorite';

export default {
  components: {
    SaveFavorite,
  },
  mixins: [productSizes, likable],
  props: {
    product: {
      type: Object,
      required: true,
    },
    likable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    openModal: false,
    liked: false,
  }),
};
