import { render, staticRenderFns } from "./ListOfProducts.vue?vue&type=template&id=3aad5159&scoped=true&"
import script from "./ListOfProducts.vue?vue&type=script&lang=js&"
export * from "./ListOfProducts.vue?vue&type=script&lang=js&"
import style0 from "./ListOfProducts.vue?vue&type=style&index=0&id=3aad5159&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aad5159",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCard: require('/data/www/marktplaats.insert.nl/insert/nuxt/components/general/ProductCard.vue').default})
