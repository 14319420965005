//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    slug: {
      type: String,
      default: '',
    },
    organisationPage: {
      type: Boolean,
      default: false,
    },
    projectPage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    uniqId: Math.floor(Math.random() * 10000000),
    swiperOptions: {
      slidesPerView: 4,
      spaceBetween: 15,
      freeMode: true,
      pagination: {
        el: 'swiper-pagination-',
      },
      breakpoints: {
        1024: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        },
        320: {
          slidesPerView: 1,
        },
      },
    },
    showPagination: false,
  }),
  computed: {
    materialQuery() {
      if (this.organisationPage || this.projectPage) {
        return this.organisationPage ? {organisation_slug: this.slug} : {project_slug: this.slug};
      }
      return {};
    },
  },
  created() {
    this.swiperOptions.pagination = {
      el: '.swiper-pagination-' + this.uniqId,
    };
  },
  mounted() {
    this.setShowPagination();
    window.addEventListener('resize', () => {
      this.setShowPagination();
    });
  },
  methods: {
    setShowPagination() {
      const windowWidth = window.innerWidth;
      const breakpointWidths = Object.keys(this.swiperOptions.breakpoints);
      const breakpointWidth = breakpointWidths.find((width, index) => {
        return index + 1 === breakpointWidths.length || (windowWidth >= width && windowWidth < breakpointWidths[index + 1]);
      });

      this.showPagination = (this.products.length / this.swiperOptions.breakpoints[breakpointWidth].slidesPerView) > 1;
    },
  },
};
