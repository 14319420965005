import {mapGetters} from 'vuex';
import deleteFavoriteProducts from '~/graphql/mutations/dashboard/deleteFavoriteProducts';

export default {
  data: () => ({
    openModal: false,
  }),
  computed: {
    ...mapGetters({
      hasFavorite: 'favorites/hasFavorite',
    }),
    isFavorite() {
      return this.$store.state.auth.loggedIn ? this.product.is_favorite : this.hasFavorite(this.product.id);
    },
  },
  methods: {
    async handleClickLike() {
      if (this.isFavorite) {
        try {
          if (this.$store.state.auth.loggedIn) {
            await this.handleAuthenticatedFavorite();
          } else {
            this.handleLocalFavorite();
          }
          this.$toast.success('Materiaal verwijderd van je favorietenlijst.');
        } catch (e) {
          console.error(e);
          this.$toast.error('Er is iets mis gegaan tijdens het verwijderen van het product uit uw favorieten. Probeer het later nog eens.');
        }
      } else {
        this.openModal = !this.openModal;
      }
    },
    async handleAuthenticatedFavorite() {
      try {
        await this.$apollo.mutate({
          mutation: deleteFavoriteProducts,
          variables: {
            products: [this.product.id],
          },
        });
        this.product.is_favorite = false;
      } catch (e) {
        console.error(e);
      }
    },
    handleLocalFavorite() {
      this.$store.commit('favorites/removeFavorite', this.product);
    },
  },
};
