export default {
  computed: {
    afmetingen() {
      return this.getAfmetingen();
    },
  },
  methods: {
    getAfmetingen() {
      const defaultUnit = 'cm';
      const sizes = [];
      const sizesWithUnit = [];
      const units = [];

      if (this.product.lengtehoogte) {
        const unit = this.product.eenheid_lengtehoogte || defaultUnit;
        sizes.push(this.product.lengtehoogte);
        sizesWithUnit.push(this.product.lengtehoogte + ' ' + unit);
        units.push(unit);
      }

      if (this.product.breedte) {
        const unit = this.product.eenheid_breedte || defaultUnit;
        const width = this.product.breedte + (this.product.breedte_2 ? ('/' + this.product.breedte_2) : '');
        sizes.push(width);

        sizesWithUnit.push(width + ' ' + unit);

        units.push(unit);
      }

      if (this.product.hoogtedikte) {
        const unit = this.product.eenheid_hoogtedikte || defaultUnit;
        sizes.push(this.product.hoogtedikte);
        sizesWithUnit.push(this.product.hoogtedikte + ' ' + unit);
        units.push(unit);
      }

      if (sizes.length === 0) {
        return '';
      }

      const nrOfUnits = [...new Set(units)].length;

      if (nrOfUnits > 1) {
        return sizesWithUnit.join(' x ');
      }

      return sizes.join(' x ') + ' ' + units[0];
    },
  },
};
